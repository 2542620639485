import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import { HandleDelete, HandleDuplicate } from 'components/';
import { formatCurrency } from 'helpers';
import { options } from 'constants/table';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';

import { CourseData } from 'services/dataService';
import { statusCourses, statusYesNo } from 'constants/status';
import { toast } from 'react-toastify';
import { Search, FileCopy } from '@material-ui/icons';
import FeaturedCoursesData, {
  FeatureCoursesType,
} from 'services/dataService/FeaturedCoursesData';

const FieldWithStatus = ({ circleStyle, text }) => (
  <div style={{ display: 'flex' }}>
    <CircleIcon style={circleStyle} fontSize="small" />
    <span style={{ fontWeight: 'bold', fontSize: 14 }}>{text}</span>
  </div>
);

export default () => {
  const [status, setStatus] = useState(null);
  const [name, setName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStatusDuplicate, setModalStatusDuplicate] = useState(false);
  const [route, setRoute] = useState('');
  const [routeDuplicate, setRouteDuplicate] = useState('');

  const handleModalOpen = useCallback(({ id_course }) => {
    setModalStatus(true);
    setRoute(`courses/${id_course}`);
  });

  const handleModalOpenDuplicate = useCallback(({ id_course }) => {
    setModalStatusDuplicate(true);
    setRouteDuplicate(id_course);
  });

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
    'message',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  /*const handleEdit = useCallback(
    ({ id }) => {
      history.push(`courses/${id}/edit`);
    },
    [history]
  );*/

  const onSubmit = (data) => {
    setName(data.name);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
    <div className="notranslate">
    <MaterialTable
        title={`${t('tables:course.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:course.name_column')}`,
            sorting: false,
            field: `course.name`,
            render: (rowData) => rowData.course.name,
          },
          {
            title: `${t('tables:category.name_column')}`,
            field: `category.name`,
            sorting: false,
            render: (rowData) => rowData.category.name,
          },
          {
            title: `${t('tables:course.featured_column')}`,
            field: `featured`,
            sorting: false,
            render: (rowData) => (
              <FieldWithStatus
                circleStyle={statusYesNo[rowData.featured]?.style}
                text={t(statusYesNo[rowData.featured]?.title)}
              />
            ),
          },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            sorting: false,
            render: (rowData) => (
              <FieldWithStatus
                circleStyle={statusCourses[rowData.status]?.style}
                text={t(statusCourses[rowData.status]?.title)}
              />
            ),
          },
        ]}
        data={async (query) => {
          try {
            const response = await CourseData.getCourses({
              page: query.page + 1,
              limit: query.pageSize,
              name,
              status,
            });

            const featuredInternal = await FeaturedCoursesData.getFeaturedCourses(
              FeatureCoursesType.internal
            );
            const featuredExternal = await FeaturedCoursesData.getFeaturedCourses(
              FeatureCoursesType.external
            );
            const featuredCourses = featuredInternal.data.coursesList.concat(
              featuredExternal.data.coursesList
            );

            const courses = response.data.courses.map((course) => {
              course.featured = featuredCourses.some(
                (feat) => feat.id_company_course === course.id
              );
              return course;
            });

            return {
              data: courses,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Courses.input_course')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControl style={{ minWidth: 100 }}>
                        <InputLabel id="status">
                          {t('form:User_solicitation.input_status_select')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value="all">
                                {t('label:commons.label_status_all')}
                              </MenuItem>
                              <MenuItem value="1">
                                {t(statusCourses[1]?.title)}
                              </MenuItem>
                              <MenuItem value="2">
                                {t(statusCourses[2]?.title)}
                              </MenuItem>
                              <MenuItem value="3">
                                {t(statusCourses[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 'all'}
                          value={status ? status : 'all'}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t(
              'tables:commons.body.emptyDataSourceMessage'
            ),
            filterRow: {
              filterTooltip: t('tables:commons.body.filterRow.filterTooltip'),
            },
          },
          header: {
            actions: t('tables:commons.header.label_action'),
          },
          pagination: {
            labelDisplayedRows: t(
              'tables:commons.pagination.labelDisplayedRows'
            ),
            labelRowsSelect: t('tables:commons.pagination.labelRowsSelect'),
            firstTooltip: t('tables:commons.pagination.firstTooltip'),
            previousTooltip: t('tables:commons.pagination.previousTooltip'),
            nextTooltip: t('tables:commons.pagination.nextTooltip'),
            lastTooltip: t('tables:commons.pagination.lastTooltip'),
          },
          toolbar: {
            exportName: t('tables:commons.toolbar.exportName'),
            exportTitle: t('tables:commons.toolbar.exportTitle'),
            searchPlaceholder: t('tables:commons.toolbar.searchPlaceholder'),
            searchTooltip: t('tables:commons.toolbar.searchTooltip'),
          },
        }}
        options={options}
        actions={[
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.class_action')}`,
            onClick: (event, rowData) => {
              history.push(`/courses/${rowData.id_course}/lessons`, rowData.id);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'settings',
            tooltip: `${t('tables:commons.actions.config_action')}`,
            onClick: (event, rowData) => {
              history.push(`/courses/${rowData.id_course}/config`);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: () => <FileCopy color="primary" />,
            tooltip: `${t('tables:commons.actions.duplicate_course')}`,
            onClick: (event, rowData) => handleModalOpenDuplicate(rowData),
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },
        ]}
        //onOrderChange={(orderBy, orderDirection) => {console.log(orderBy, orderDirection)}}
      />

    </div>

      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
        customMessage={t('message:courses.delete')}
      />
      <HandleDuplicate
        closeModal={setModalStatusDuplicate}
        isOpen={modalStatusDuplicate}
        courses={routeDuplicate}
        tableRef={tableRef?.current}
        customMessage={t('message:courses.duplicate')}
      />
    </>
  );
};
