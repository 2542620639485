import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function DialogGamification({
    coin,
    courseName,
    modalStatus,
    closeModal,
    logo
}) {
    const { t } = useTranslation(['toastify', 'modal']);

    const handleClose = () => {
        closeModal(false);
    };

    return (
        <Dialog
            open={modalStatus}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm" 
        >
            <DialogTitle style={{ textAlign: "center" }}>
                <h4
                    style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '40px',
                        lineHeight: '61px'
                    }}
                >
                    Parabéns <span>&#x1F389;&#x1F389;&#x1F389;</span>
                </h4>
            </DialogTitle>

            <DialogContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    <Typography
                        variant="h6"
                        component="h6"
                        style={{
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            lineHeight: '22px',
                            fontWeight: '500',
                            fontStyle: 'normal',
                            marginTop: '8px'
                        }}
                    >
                        Você acaba de ganhar 10 {coin}
                        <img
                            className="coinImage"
                            src={"/images/Coin/coin.png"}
                            alt="Moeda"
                            style={{
                                width: '20px',
                                height: '20px',
                                margin: '0 2px -5px 2px'
                            }}
                        /> 
                        por concluir:
                    </Typography>

                    <Typography
                        variant="h5"
                        style={{
                            marginTop: '15px',
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            lineHeight: '22px'
                        }}
                    >
                        <strong>{courseName}</strong>
                    </Typography>
                </DialogContentText>

                <DialogContentText>
                    <Typography
                        variant="h6"
                        style={{
                            marginTop: "30px",
                            fontSize: "15px"
                        }}
                    >
                        Os pontos podem ser trocados por premiações de acordo com as{" "}
                        <a 
                            className="textPoliticas" 
                            href={'/score'}
                            target="_blank"
                            style={{
                                color: "#1D7FF1"
                            }}
                        >
                            políticas da empresa.
                        </a>
                    </Typography>
                </DialogContentText>

                <DialogContentText>
                    <img
                        className="coinImage"
                        src={logo}
                        alt="Logotipo"
                        style={{ marginTop: '25px', maxWidth: "166px", height: "40px" }}
                    />
                </DialogContentText>
            </DialogContent>
            {/* <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
