const notificationTemplates = {
    pointsEarned: ({ name, qtdPoints, coinName, transactionType }) => ({
        title: transactionType === "CREDIT" ? "Você ganhou pontos extras!" : "Você perdeu pontos!",
        content: transactionType === "CREDIT" ? `Olá, ${name}!\n\nBoa notícia! Você recebeu ${qtdPoints} ${coinName}.\n\nConfira agora seu saldo atualizado na plataforma e veja suas opções na loja de recompensas!\n\nContinue aproveitando nossos benefícios.\n\nAbraços,\nEquipe Cresça Mais` : `Olá, ${name}!\n\n  Você perdeu ${qtdPoints} ${coinName}.\n\n Confira agora seu saldo atualizado na plataforma e veja suas opções na loja de recompensas!\n\n \n\nAbraços,\nEquipe Cresça Mais`,
    }),
    newRewards: ({ name }) => ({
        title: "Novidades na loja de recompensas!",
        content: `Olá, ${name}!\n\n Temos novidades incríveis para você! Acabamos de adicionar novos prêmios à loja de recompensas. Não perca tempo, confira agora e escolha seu favorito. \n \n Esperamos que você goste das novidades! \n \n Abraços. \n\n Equipe Cresça Mais`,
    }),
    rescueCompletedStudent: ({ name, item }) => ({
        title: "Seu resgate foi um sucesso!",
        content: `Olá, ${name}!\n\n Uau! Você resgatou ${item}. Verifique seus e-mails ou acesse a plataforma para mais instruções sobre como retirar sua recompensa \n \n Parabéns por sua evolução! \n \n Abraços. \n\n Equipe Cresça Mais`,
    }),
    rescueCompletedAdmin: ({ name, item }) => ({
        title: "Resgate de recompensa realizado!",
        content: `Olá, ${name}!\n\n O aluno ${name} resgatou ${item} na loja. Confira os detalhes na plataforma! \n \n Abraços. \n\n Equipe Cresça Mais`,
    }),
    finishCourse: ({ name, courseName, qtdPoints, coinName }) => ({
        title: "Parabéns pela conquista, " + name,
        content: `Olá, ${name}!\n\n Parabéns! Você concluiu o curso ${courseName} e ganhou ${qtdPoints} ${coinName}! Acesse a loja agora e veja o que você pode conquistar com sua pontuação. \n \n Abraços. \n\n Equipe Cresça Mais`,
    }),
};

export default notificationTemplates;
