import { Box, Button, Grid, Typography, Card, CardContent } from '@material-ui/core';
import { InfoOutlined, Send, Visibility, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO, set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LessonQuestionsData from 'services/dataService/LessonQuestionsData';
import { LessonContentData, RatingData } from 'services/dataService';
import CheckboxEvalution, {
  QuestionTypeEnum,
} from 'views/Exams/components/CheckboxEvalution';
import moment from 'moment';
import RadioButtonEvalution from 'views/Exams/components/RadioButtonEvalution';
import DialogGamification from 'components/DialogGamification';
import ModalRating from 'views/RatingCourses/components/ModalRating/Index';
import GamificationData from 'services/dataService/GamificationData';
import generateNotifications from 'helpers/NotificationsCenter'

const ExamLearnContent = ({ finishedCourse, content, courseId, hideAnotation, course, next }) => {

  const classes = useStyles();
  const { id: id_user, name: name_user, id_company, email} = useSelector((state) => state.auth);  
  const { company } = useSelector((state) => state);

  const [lessonsExams, setLessonsExams] = React.useState([]);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [availableAttempts, setAvailableAttempts] = React.useState(0);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [error, setError] = React.useState(false);
  const [can_redo, setCanRedo] = React.useState(true);
  const [showAnswers, setShowAnswers] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [buttonShowAnswers, setButtonShowAnswers] = React.useState(true);
  const [finished, setFinished] = React.useState(false);
  const [resultsExam, setResultsExam] = React.useState([]);
  const [pendingClasses, setPendingClasses] = React.useState([]);
  const [checkApprovedExam, setCheckApprovedExam] = useState(false);
  const [enableExams, setEnableExams] = useState([]);
  const [disapprovalList, setDisaprovalList] = useState(false);
  const [isFinalLesson, setIsFinalLesson] = useState(false);
  const [lasttGrade, setLasttGrade] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const { t } = useTranslation(['views']);
  const [isSending, setIsSending] = useState(false);
  const [modalStatusPreview, setModalStatusPreview] = useState(false);
  const [openRatingExams, setOpenRatingExams] = useState(false)
  const [isShould, setIsShould] = useState(false);
  const [sendFinished, setSendFinished] = useState(false);
  const [doRating, setDoRating] = useState(true);
  const [coinName, setCoinName] = React.useState();

  const history = useHistory();

  async function handleDisapprovalList(id_lesson_content, id_user, id_course) {
    const approvedExam = await LessonQuestionsData.checkApprovedExam(id_lesson_content, id_user, id_course);
    setCheckApprovedExam(approvedExam.data.isApproved);
    setIsFinalLesson(approvedExam.data.isFinalLesson);
    setDisaprovalList(approvedExam.data.disapprovalList);
  };
  async function showLastIncorrectAnswers() {
    try {
      const response = await LessonQuestionsData.getExamDetails(id_user, content.id);
      setButtonShowAnswers(true)
      setAnswers(response.data.lesson_content_exams || []);
    } catch (error) {
      setButtonShowAnswers(false)
      console.error('Avaliação ainda não foi realizada:', error);
    }
  }

  const loadExam = async () => {
    setFlag(true);
    setOpenRatingExams(true)
    try {
      await handleDisapprovalList(content.id, id_user, courseId);
      const { data: pendingClasses } = await LessonQuestionsData.getPendingClasses(
        id_user,
        courseId,
        content.id
      )

      if (pendingClasses.length > 0) {
        setPendingClasses(() => pendingClasses)
        setFinished(() => true);
      }

      const { data: lessonExam } = await LessonQuestionsData.getlessonQuestions(
        id_user,
        content?.id
      );

      try {
        if (pendingClasses.length === 0) {
          const { data: examResult } = await LessonQuestionsData.getExamResult(
            id_user,
            content?.id
          );

          setFinished(true);
          setResultsExam(examResult);

          const {
            data: newExamAttempt,
          } = await LessonContentData.checkNewExamAttempt(id_user, content?.id);

          if (examResult.length > 0) {
            const attemps = examResult.sort((a, b) => {
              return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            })[0]?.available_exam_attempts || 0;

            setAvailableAttempts(attemps);
          }

          function betterGrade() {
            for (let i = 0; i < examResult.length; i++) {
              if (examResult[i].score == '1.00') {
                return true;
              }
            }
          }

          function openModal() {
            examResult.sort((a, b) => b.score - a.score);
            let x = 0
            x = examResult[0].score * 100;
            if (flag == true) {
              if ((x >= course.course.score) && (company?.show_gamification)) {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                }, 15000);
              }
              else {
                setOpen(false)
              }
            }
          };


          function lastGrade() {
            examResult.sort((a, b) => b.score - a.score);
            let x = 0
            x = examResult[0].score * 100;
            if (x >= course.course.score) {
              setLasttGrade(true)
            }
            else {
              setLasttGrade(false)
            }
          }

          lastGrade()
          openModal()

          if (newExamAttempt && newExamAttempt.can_redo && !betterGrade()) {
            setCanRedo(true);
          } else {
            setCanRedo(false);
          }
        }
      } catch (error) {
        setFinished(false);
      }

      setName(lessonExam?.name || '');
      setDescription(lessonExam?.description || '');
      setNumberOfAttempts(lessonExam?.max_exam_attempts || '');
      setLessonsExams(lessonExam?.lesson_content_exams || []);
    } catch (error) {
      console.log(error);
    }

  };

    async function getGamification(id) {
      const gamificationData = await GamificationData.getGamificationByIdCompany({ "id_company": id_company });
      const { data } = gamificationData;
      data.map((gamification, index) => {
        setCoinName(gamification.coin_name);
      })
    }



  const handleSubmitCallback = async (sucess) => {
    if (sucess) {
      setSendFinished(true);
      localStorage.setItem(`hasRatedCourse_${id_user}_${course.id_company_course}`, 'true');
      getGamification();
      const notificationsResponse = await generateNotifications("finishCourse", {
        name: name_user,
        courseName: course.name,
        qtdPoints: course.course.score,
        coinName: coinName,
        read: false,
        email: email
      });
    }
  };

  const calculateAvailableAttempts = () => {

    let examAvailableAttempts = availableAttempts > 0 ? availableAttempts : numberOfAttempts

    return Math.max(0, examAvailableAttempts - 1);
  };

  const onExamResultsSend = async () => {
    if (isSending) {
      return;
    }

    setIsSending(true);

    const lessonResult = lessonsExams?.map((exam) => {
      const answers = exam?.lesson_content_exam_questions
        ?.filter((questionsFilter) => questionsFilter?.is_correct === true)
        .map(({ id }) => {
          return { id_lesson_content_exam_questions: id };
        });

      return {
        id_lesson_content_exams: exam?.id,
        answers,
      };
    });

    let availableExamAttempts = calculateAvailableAttempts();

    await LessonQuestionsData.sendExamResult(
      id_user,
      content?.id,
      lessonResult,
      availableExamAttempts
    );

    setFinished(true);
    finishedCourse({
      finished: true,
      video_last_sec: null,
      audio_last_sec: null,
    });

    loadExam();
    showLastIncorrectAnswers();
    setIsSending(false);
  };

  const onHandleRedoExam = () => {
    const hasRatedCourse = localStorage.getItem(`hasRatedCourse_${id_user}_${course.id_company_course}`);
    if (hasRatedCourse) {
      setDoRating(false)
    }
    setButtonShowAnswers(false);
    setAnswers(false);
    setFinished(false);
  };

  const onHandleShowAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const onShouldOpenModalRating = async () => {
    try {
      const response = await RatingData.hasRating(id_user, course.id_company_course);

      if (response && response.data.shouldOpenModal) {
        setIsShould(true);
      }
    } catch (error) {
      console.error('Erro ao verificar se o modal deve ser aberto:', error);
    }
  };

  const onBlockExam = async () => {
    try {
      const response = await LessonQuestionsData.getWorkloadExam(id_user, courseId);
      setEnableExams(response.data)      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (hideAnotation) {
      hideAnotation(true);
    }
    onBlockExam();
  }, []);

  useEffect(() => {
    loadExam();
    onShouldOpenModalRating();
    showLastIncorrectAnswers();
    getGamification();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCertificateClick = () => {
    history.push('/certificates');
  };

  return (
    <>
       <DialogGamification
          coin={coinName}
          courseName={course.course.name}
          modalStatus={open}
          closeModal={setOpen}
          logo={company?.theme?.logo?.url}
       />
      {pendingClasses.length > 0 && finished ? (
        <Box className={classes.headerBox}>
          <Typography className={classes.examSubmitted}>{t('views:Exam.pending_classes')}</Typography>
          {pendingClasses
            .map((result, index) => (
              <Typography key={index} className={classes.submissionDate}> <span className={classes.dateNote}>
              </span > - {t('views:Exam.class')}: <span className={classes.dateNote}>{result?.title}</span>
              </Typography>
            ))
          }
        </Box>
      ) : (
        <Box className={`${classes.root} notranslate`} >
          <Box className={classes.headerBox}>
            {resultsExam.length > 0 && finished ? (
              <>
                <Typography className={classes.examSubmitted}>{t('views:Exam.already_submitted')}</Typography>
                <Typography style={{}} />
                <span className={classes.minNote}>{t('views:Exam.average_grade')}{course.course.score}</span>

                <div style={{ margin: '15px 0' }}>
                  <Typography variant="h4" style={{ color: lasttGrade ? 'green' : '#dd6e05' }}>
                    {lasttGrade ? (
                      <div>
                        Parabéns, você foi aprovado!<br /> <br />
                        {(disapprovalList.length > 0 && !checkApprovedExam && isFinalLesson) &&
                          <>
                            <Typography className={classes.examSubmitted}>{t('views:Exam.disapprovalList')}</Typography>
                            <br />
                            <Typography variant="h4" style={{ color: 'red' }}>
                              Lista das provas pendentes:
                            </Typography>
                            {disapprovalList.map((result, index) => (
                              <Typography key={index} className={classes.submissionDate}> <span className={classes.dateNote}>
                              </span > - {t('views:Exam.class')}: <span className={classes.dateNote}>{result?.name}</span>
                              </Typography>
                            ))}
                          </>
                        }
                        {(checkApprovedExam && isFinalLesson) &&
                          <Typography
                            variant='h5'
                          >
                            <a href="" onClick={handleCertificateClick}> Acesse o seu certificado aqui</a>.
                          </Typography>
                        }
                      </div>
                    ) : (
                      <div>
                        Você não obteve nota suficiente, tente novamente.<br />
                      </div>
                    )}
                  </Typography>
                </div>
                {resultsExam
                  .map((result, index) => (
                    <Typography key={index} className={classes.submissionDate}>{t('views:Exam.submission_date')}: <span className={classes.dateNote}>{format(
                      parseISO(result?.createdAt),
                      'dd/MM/yyyy HH:mm'
                    )}</span> - {t('views:Exam.submission_grade')}: <span className={classes.dateNote}>{(parseFloat(result?.score) * 100).toFixed(2)}</span>
                    </Typography>
                  ))
                  .reverse()
                }
                <Typography className={classes.info}>
                  {can_redo
                    ? (
                      <>
                        <InfoOutlined style={{ height: 16, marginTop: 1 }} />
                        {t('views:Exam.info_attempts').replace('<data>', (availableAttempts).toString())}
                        <div className={classes.containerButton}>
                          <Button className={classes.redoButton} onClick={onHandleRedoExam}>
                            <Edit className={classes.space} />
                            {t('views:Exam.redo_button')}
                          </Button>
                        </div>

                      </>
                    ) : (
                      <>
                        <InfoOutlined style={{ height: 16, marginTop: 1, alignSelf: 'flex-start' }} />
                        {t('views:Exam.info_ask_new_exam')}
                      </>
                    )}

                </Typography>
              </>
            ) : (
              <>
                {(isShould && doRating) && (
                  <>
                    {openRatingExams && next === undefined && (
                      <ModalRating
                        setOpen={setOpenRatingExams}
                        company={course.id_company}
                        user={id_user}
                        course={course}
                        flag={true}
                        handleSubmitCallback={handleSubmitCallback}
                      />
                    )}
                  </>
                )
                }
                <Typography className={classes.nameTitle}>{name}</Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </>
            )}
          </Box>
          {!finished && (
              <>
                {enableExams?.show_exam && !enableExams?.isSameOrBefore ? (
                  <Card variant="outlined" className={classes.examWarning}>
                    <CardContent>
                      <Typography variant="h5" color="textPrimary">
                        Avaliação indisponível no momento
                      </Typography>
                      <Typography variant="body1" color="textSecondary" style={{ marginTop: '8px' }}>
                        Conforme a carga horária do curso, a avaliação estará disponível em:
                      </Typography>
                      <Typography variant="h5" color="primary" className={classes.dateText}>
                       📅 {moment(enableExams.examReleaseDate).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" style={{ marginTop: '8px' }}>
                        Retorne na data mencionada e conclua seu curso! 🚀🚀
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <>
                    {lessonsExams.map((exam, index) => (
                      <React.Fragment key={index}>
                        <Typography className={classes.marginTop} />
                        {exam?.type_exam === QuestionTypeEnum.RADIO_QUESTION ? (
                          <RadioButtonEvalution
                            exam={exam}
                            index={index}
                            onCopy={() => {}}
                          />
                        ) : (
                          <CheckboxEvalution
                            exam={exam}
                            index={index}
                            onCopy={() => {}}
                            error={error}
                          />
                        )}
                      </React.Fragment>
                    ))}
                    <Typography className={classes.marginTop} />
                    <Box className={classes.footer}>
                      <Grid container spacing={2} justify="center" alignContent="center">
                        <Grid item xs={12} align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onExamResultsSend}
                            disabled={isSending}
                          >
                            <Send />
                            <Typography className={classes.sendTxt}>Enviar</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </>
            )}
        </Box>
      )}
      {(buttonShowAnswers && company?.show_answers == true) && (
        <div className={classes.containerButton}>
          <Button style={{ marginRight: '67px' }} className={classes.redoButton} onClick={onHandleShowAnswers}>
            <Visibility className={classes.space} />
            {t('views:Exam.show_answers')}
          </Button>
        </div>
      )}
      {showAnswers && (
        <div className={classes.root}>
          <div className={classes.headerBox}>
            <span className={classes.dateNote}>Segue abaixo a(s) questão(ões) assinaladas como incorreta(s).</span>
            {answers.length > 0 && (
              answers.map((exam, index) => (
                <React.Fragment key={index}>
                  <Typography className={classes.marginTop} />
                  {exam?.type_exam === QuestionTypeEnum.RADIO_QUESTION ? (
                    <RadioButtonEvalution
                      exam={exam}
                      index={index}
                      isReviewMode={true}
                      onCopy={() => { }}
                    />
                  ) : (
                    <CheckboxEvalution
                      exam={exam}
                      index={index}
                      onCopy={() => { }}
                      isReviewMode={true}
                      error={error}
                    />
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  marginTop: {
    paddingTop: theme.spacing(3),
  },
  headerBox: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 8,
    borderBottomColor: theme.palette.info.custom,
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  nameTitle: {
    ...theme.typography.h2,
    fontSize: 35,
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 17,
    paddingBottom: theme.spacing(2),
  },
  space: {
    paddingRight: '5px',
  },
  textInfo: {
    fontWeight: 'bold',
  },
  footer: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 8,
  },
  sendTxt: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.white,
  },
  examSubmitted: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),
  },
  submissionDate: {
    marginBottom: theme.spacing(1),
  },
  dateNote: {
    fontWeight: 500,
  },
  info: {
    display: 'flex',
    color: '#9E9E9E',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    borderTopColor: theme.palette.divider,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    alignItems: 'center',
    width: '100%'
  },
  minNote: {
    display: 'flex',
    color: '#9E9E9E',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 13,
  },
  containerButton: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  },
  redoButton: {
    marginLeft: theme.spacing(1),
    fontWeight: 300,
    color: theme.palette.white,
    backgroundColor: theme.palette.info.main,
    fontSize: 12,
    width: 'fit-content',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  examWarning: {
    borderLeft: "5px solid #176AE6", // Um detalhe laranja na lateral
    padding: "16px",
    marginTop: "16px",
  },
  dateText: {
    fontWeight: "bold",
    marginTop: "8px",
    marginBottom: "8px"
  },
}));

export default ExamLearnContent;