import React from 'react';
import {
  Box,
  Chip,
  Grid,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setTeam } from 'store/modules/teams/actions';
import { Autocomplete } from '@material-ui/lab';
import { Stack } from '@mui/material';

export default function TeamConfiguration() {
  const { data, students } = useSelector((store) => store.teams);
  const [value, setValue] = React.useState(null);
  
  const dispatch = useDispatch();

  const copy = (obj) => JSON.parse(JSON.stringify(obj));

  const handleOnChange = (e, field) => {
    dispatch(setTeam({ ...data, [field]: e.target.value }));
  };

  const handleFilter = (e, value) => {
    if (!value) {
      return;
    }
    const { id, name, cpf } = value;
    setValue({ id, name, cpf });
    const admins = copy(data.admins || []);
    admins.push({ id, name });
    dispatch(setTeam({ ...data, admins }));
  };

  const handleDelete = (id) => {
    const oldAdmins = copy(data.admins);
    const admins = oldAdmins.filter((ad) => ad.id !== id);

    dispatch(setTeam({ ...data, admins }));
  };


  const { t } = useTranslation(['form']);
  const classes = useStyles();

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <Interweave content={t('form:Team.Configuration.text1')} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <Interweave content={t('form:Team.Configuration.text2')} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <Interweave content={t('form:Team.Configuration.text3')} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <Typography>
              <Interweave content={t('form:Team.Configuration.text4')} />
            </Typography> */}
          </Grid>
        </Grid>

        <Box className={classes.spacing} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              value={data.name || ''}
              variant="outlined"
              onChange={(e) => handleOnChange(e, 'name')}
              label={t('Nome do time')}
              error={data.name === ''}
              helperText={data.name === '' ? 'Campo obrigatório' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={data.description || ''}
              onChange={(e) => handleOnChange(e, 'description')}
              variant="outlined"
              label={t('form:Team.Configuration.label2')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              clearOnEscape
              value={value}
              options={students.filter(
                (s) => !data.admins.map((a) => a.id).includes(s.id)
              )}
              getOptionLabel={(option) =>
                `${option?.name || ''} - ${option?.email || ''} - ${option?.cpf || ''}`
              }
              getOptionSelected={(opt, value) => opt.id === value?.id}
              onChange={handleFilter}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label={"Administrador do time"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            >
              {data.admins?.map((adm) => (
                <Chip
                  style={{ marginRight: 10, marginTop: 10 }}
                  key={`admin-${adm.id}`}
                  color="primary"
                  label={adm.name}
                  onDelete={() => handleDelete(adm.id)}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: theme.spacing(4),
  },
}));
