import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import useWindowDimensions from 'customHooks/DimensionHook';
import { EnrollmentData } from 'services/dataService';
import GamificationData from 'services/dataService/GamificationData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatarTopSpacing: {
    marginTop: theme.spacing(2),
  },
  avatarTextLeftSpacing: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: 60,
    height: 60,
  },
  logo: {
    maxHeight: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  iconStyle: {
    marginTop: '0px',
    fontSize: '25px',
    color: '#F1C40F',
  },
  linkDocs: {
    color: '#ff0000',
    textDecoration: 'none',
  },
  star: {
    margin: '-100px',
  },
  scoreUserWidget: {
    width: "100%",
    padding: "20px",
    fontSize: ".9em",
    display: "flex",
    flexDirection: "column",
    gap: ".3rem"
  },
  scoreUserWidgetCoins: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: ".3rem"
  },
  scoreUserWidgetButton: {
    color: theme.palette.primary.main,
    marginTop: 5
  }
}));

function getReward(e) {
  e.stopPropagation()
}

const Profile = (props) => {
  const [disable, setDisable] = React.useState(false);
  const [enrollment, setEnrollment] = React.useState(null);
  const [coinCount, setCoinCount] = React.useState('');
  const [totalCoins, setTotalCoins] = useState(null);
  const [data, setData] = React.useState();
  const [coinName, setCoinName] = React.useState();
  
  const { className, ...rest } = props;
  const { company } = useSelector((state) => state);
  const { name, id, group, type, avatar, email, company_logo, id_company } = useSelector(
    (state) => state.auth
  );
  const classes = useStyles();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const primaryAvatar = (
    <Typography className={classes.name} variant="h6">
      Bem vindo, {name?.split(' ')?.slice(0, 1)}
    </Typography>
  );

  let comp = [
    {
      comp: 'mant',
      id: 'f6747104-f86a-4365-9aa5-62bf3cb0b317',
      link: 'https://docs.google.com/document/d/1As_pG1Rmdf9d0Y_RnkuL7NV8iREdQBIOHydhAd--Ogc/edit?usp=sharing',
      coin: 'MantiCoins'
    },
    {
      comp: 'dream',
      id: '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5',
      link: 'https://docs.google.com/document/d/1g9eeA6x_zNELLss5uv-sbLhEg32J_fme0Mggb1zszgU/edit?usp=sharing',
      coin: 'Dream Coins'
    },
  ];

  async function getGamification(id) {
    const gamificationData = await GamificationData.getGamificationByIdCompany({ "id_company": id_company });
    const { data } = gamificationData;
    data.map((gamification, index) => {
      setCoinName(gamification.coin_name);
    })
  }

  const getTotalCoins = async () => {
    const data = await GamificationData.getTotalCoinsByUserId({ id_user: id });
    const { data: { total } } = data;
    setTotalCoins(total);
  }

  const getData = async (filter) => {

    const enrollment = await EnrollmentData.getEnrollment(id, filter);

    const newEnrollment = enrollment.data.enrollments.map((elem) => {
      return {
        id_user: elem.id_user,
        id_company: elem.id_company,
        id_company_course: elem.id_company_course,
        progress: elem.company_course.course?.percent_progress,
        status: elem.company_course.status,
        score_course: elem.company_course.course?.score,
        score_exam: elem.company_course.course?.score_exam,
        finished: elem.finished_at,
        online_classroom: elem.company_course.online_classroom,
      };
    });

    const scoreExam = newEnrollment.map((elem) => {
      return elem.score_course;
    })


    const countCoins = newEnrollment.filter((elem) => {
      let cc;
      if (id_company === 'f6747104-f86a-4365-9aa5-62bf3cb0b317') {
        cc = elem.progress == 100 &&
          elem.online_classroom === true &&
          elem.finished >= '2024-04-01' &&
          (elem.score_exam >= elem.score_course ||
            elem.score_course === 0);
      } else {
        cc = elem.progress == 100 && (elem.score_exam >= elem.score_course || elem.score_course === 0);
      }
      return cc;
    }).length;

    setCoinCount(countCoins * 10)
    setData(newEnrollment);
  };

  const onCoin = (idCompany) => {
    if (comp.some(item => item.id === idCompany) && (group == 'Aluno' || group == 'Estudante' || group == 'Colaborador')) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const { t } = useTranslation(['form']);

  const secundaryAvatar = <Typography variant="body2">{group}</Typography>;
  const tAvatar = <Typography className='' variant='body1'>Seu Score:</Typography>

  const star = <div className=''>
    <Typography
      className='starText'
      variant='body2'>
      <img
        className='coinImage' src={"/images/Coin/coin.png"}
        alt="Minha Imagem"
        style={{ width: '20px', height: '20px', margin: '-5px', marginRight: '1px' }} />
      {totalCoins} {comp.some(item => item.id === id_company) ? comp.find(item => item.id === id_company).coin : coinName}
    </Typography>
  </div>

  const link = <Typography
    variant='body1'>
    <a
      className="linkDocs"
      href={comp.some(item => item.id === id_company) ? comp.find(item => item.id === id_company).link : null}
      target={"_blank"} >
      Obter Recompensas
    </a>
  </Typography>

  useEffect(() => {
    onCoin(id_company);
    getData();
    getTotalCoins();
    getGamification();
  }, []);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className, 'notranslate')}
      onClick={() => history.push(`/users/edit-profile`)}
    >
      {/* <ListItem>
        <img className={classes.logo} src={company_logo?.url || logo} />
      </ListItem> */}
      <ListItem button className={classes.avatarTopSpacing}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: width >= 600 ? 'center' : 'flex-start',
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={
                  // avatar?.url || `https://www.gravatar.com/avatar/${emailHash}`
                  avatar?.url || '/images/avatars/noUserSbg.png'
                }
                to="/users/edit-profile"
              />
            </ListItemAvatar>
          </Grid>
          <Grid item xs={12} md={8}>
            <ListItemText primary={primaryAvatar} secondary={secundaryAvatar} />
            {type === 2 && company?.show_gamification && (
              <div>
                <ListItemText primary={tAvatar} />
                <ListItemText
                  primary={star}
                />
                <ListItemText primary={link} onClick={getReward} />
              </div>
            )}
          </Grid>
        </Grid>
      </ListItem>
      {/* {
        type === 2 && company?.show_gamification && (
          <div className={classes.scoreUserWidget}>
            <p>Seu score:</p>
            {totalCoins && (<p className={classes.scoreUserWidgetCoins}><MonetizationOn /> {totalCoins} DreamCoins</p>)}
            <a className={classes.scoreUserWidgetButton} href="/score">Obter Recompensas</a>
          </div>
        )
      } */}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
