import React, { forwardRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CourseData } from 'services/dataService';
import history from 'services/history';


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlideDuplicate({
  courses,
  isOpen,
  closeModal,
  propsHandleConfirm,
  customMessage,
}) {
  const { t } = useTranslation(['toastify', 'modal']);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    closeModal(false);
  };


  const handleConfirm = async () => {
    setIsLoading(true); 
    try {
      const course = await CourseData.duplicateCourse(courses);
      toast.success(t('toastify:commons.toast_success_duplicate'));
      history.push(`/courses/${course.data.courseDuplicate.id}/lessons`);
      closeModal(false);
    }  catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else if (err?.response?.data?.error === 'Course cant be duplicate.') {
        toast.error(t('toastify:commons.toast_error_duplicate'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('modal:commons.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {customMessage ?? t('modal:duplicate.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('modal:commons.disagree')}
          </Button>
          <Button
            onClick={() => {
              if (!isLoading) {
                if (propsHandleConfirm) {
                  propsHandleConfirm();
                  closeModal(false);
                } else {
                  handleConfirm();
                }
              }
            }}
            variant="outlined"
            color="secondary"
            disabled={isLoading}
          >
            {isLoading ? "AGUARDE" : t('modal:commons.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
