import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export const options = {
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export function TopUsersChart({ data, backgroundColor }) {
  const { t } = useTranslation(['label']);

  const labels = data?.map((item) => item.courseName);
  const values = data?.map((item) => item.count);

  const chartData = {
    labels,
    datasets: [
      {
        hoverBackgroundColor: '#1d4f79',
        data: values,
        backgroundColor,
      },
    ],
  };

  return <HorizontalBar options={options} data={chartData} />;
}
