import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportData, { AdminReportType } from 'services/dataService/ReportData';

import {
  ReportAcademicTeamLeaderToolbar,
  ReportAcademicTeamLeaderList,
} from 'views/Reports/AcademicTeamLeader/components';
import academicReportJSON from 'constants/JSONConstants/academic-report-table-columns';
import { CustomFieldsData } from 'services/dataService';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const { t } = useTranslation(['tables']);
  const [loading, setLoading] = useState(true);

  // Load table columns
  const loadFields = async () => {
    try {
      const { data: reportFields } = await ReportData.getReportFields(
        AdminReportType.ACADEMIC
      );
      const { data: customFields } = await CustomFieldsData.getCustomFields();
      const customfieldsArray = JSON.parse(customFields?.custom_fields || '[]');

      let reportData = [];
      let serverText = false;

      if (reportFields?.table_titles?.length) {
        // API user JSON
        reportData = reportFields?.table_titles;
      } else {
        // use Local JSON
        reportData = academicReportJSON;
        reportData = reportData.concat(
          customfieldsArray?.map((field) => {
            return {
              title: field?.fieldName,
              field: field?.fieldName,
              type: field?.type,
              selected: false,
            };
          }) || []
        );
      }

      setFields(
        reportData.map(({ title, field, selected, type }) => {
          return {
            title: serverText ? title : t(title),
            field,
            hidden: !selected,
            type,
          };
        })
      );

    } catch (error) {
      setFields(
        academicReportJSON?.map(({ title, field, selected, type }) => {
          return { title: t(title), field, hidden: !selected, type };
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFields();
  }, []);

  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <ReportAcademicTeamLeaderToolbar
            fields={fields}
            onSave={async (selectedList) => {
              await ReportData.updateFields({
                type: AdminReportType.ACADEMIC,
                table_titles: selectedList,
              });

              loadFields();
            }}
          />
          <div className={classes.content}>
            <ReportAcademicTeamLeaderList fields={fields} />
          </div>
        </>
      )}
    </div>
  );
};
