import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

export function ChartCard({ title, showAddButton, children, onAdd }) {
  const classes = useStyles();
  return (
    <Card
      style={{
        minHeight: '40px',
        pageBreakInside: 'avoid',
        position: 'relative',
        pageBreakBefore: 'always'
      }} 
      className={classes.card}>
      <div 
        className={classes.cardHeader}>
        <Typography className={classes.cardTitle}>{title}</Typography>
      </div>
      <CardContent
        style={{ paddingTop: '0px' }}
        className={classes.cardContent}
      >
        {children}
      </CardContent>
    </Card>
  );
}

export const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: 'flex',
    marginLeft: '5px',
    padding: '15px 15px 15px 5px',
    justifyContent: 'left',
  },
  cardTitle: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  cardContent: {
    height: '100%',
  },
}));
