import notificationTemplates from "./notificationTemplates";

function createNotification(type, data) {
    if (!notificationTemplates[type]) {
        throw new Error(`Tipo de notificação desconhecido: ${type}`);
    }

    // Obtém dados do template
    const { title, content } = notificationTemplates[type](data);

    return [
        {
            title: title,
            content: content,
            available_at: new Date(Date.now()).toISOString(), // Converte para string ISO
            user: String(data.user).trim(), 
            company: String(data.company).trim(),
            read: data.read || false,
        }
    ];
}

export default createNotification;
