import querystring from 'querystring';
import api from '../api';

class DataServiceNotification {

  getNotifications() {
    const url = 'notifications';
    return api.get(url);
  }

  getNotificationsPage(params) {
    const queryParams = querystring.stringify(params);
    return api.get(`notifications?${queryParams}`)
  }

  updateNotification(id, data) {
    return api.put(`notifications/${id}`, data)
  }

  sendNotification(data) {
    return api.post('notifications', data);
  }

  sendNotificationEmail(data) {
    // debugger;
    return api.post('gamification-notifications', data[0]);
  }

}

export default new DataServiceNotification();
