import React from 'react';
import { Box, Slide, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { handleSelection } from 'store/modules/teams/actions';
import SelectTable from '../select-table';

const columns = [
  { label: 'Nome', field: 'name' },
  { label: 'E-mail', field: 'email' },
  { label: 'CPF', field: 'cpf' },
];

export default function TeamUsers() {
  const { students } = useSelector((state) => state.teams);
  const { t } = useTranslation(['form']);
  const [filter, setFilter] = React.useState(null);
  const dispatch = useDispatch();

  const handleOnSelect = ({ type, ids }) => {
    if (type === 'single') {
      dispatch(handleSelection({ type, ids, field: 'students' }));
      return;
    }
    dispatch(handleSelection({ type, field: 'students' }));
  };

  const handleFilter = (event, value) => {
    setFilter(value);
  };


  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box>
        <Box>
          <Typography>{t('form:Team.Users.label')}</Typography>
        </Box>
        <Box>
          <Autocomplete
            fullWidth
            clearOnEscape
            value={filter}
            options={students}
            isOptionEqualToValue={(opt, value) => opt.id === value.id}
            getOptionLabel={(option) => `${option?.name ? option.name + ' - ' : ''}${option?.email ? option.email + ' - ' : ''}${option?.cpf || ''}${option?.cpf || ''}`}
            onChange={handleFilter}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                label={t('form:Teams.search_field')}
                variant="outlined"
              />
            )}
            filterOptions={(options, { inputValue }) =>
              options.filter((option) => {
                const name = option.name || '';
                const email = option.email || '';
                const cpf = option.cpf || '';
                return (
                  (name && name.toLowerCase().includes(inputValue.toLowerCase())) ||
                  (cpf && cpf.toLowerCase().includes(inputValue.toLowerCase())) ||
                  (email && email.toLowerCase().includes(inputValue.toLowerCase()))

                );
              }).slice(0, 7)
            }
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </Box>
        <Box>
          <SelectTable
            cols={columns}
            rows={students.filter(
              (student) => !filter || student.id === filter.id
            )}
            onSelect={handleOnSelect}
            length={students.length}
            totalSelected={students.filter((s) => s.selected).length}
            rowsPerPage={50}
          />
        </Box>
      </Box>
    </Slide>
  );
}
