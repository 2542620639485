import notificationTemplatesEmail from "./notificationTemplatesEmail";

function createNotificationEmail(type, data) {
    if (!notificationTemplatesEmail[type]) {
        throw new Error(`Tipo de notificação desconhecido: ${type}`);
    }

    // Obtém dados do template
    const { title, content, email } = notificationTemplatesEmail[type](data);
    // debugger;
    return [
        {
            title: title,
            content: content,
            email: email,
        }
    ];
}

export default createNotificationEmail;
