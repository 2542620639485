import React from 'react';
import { Box, Typography, Paper, Tooltip } from "@mui/material";


export function Podium({ data = [] }) {

  if (!data) return null; 

  const podiumColors = ["#C0C0C0", "#FFD700", "#CD7F32"]; 
  const reorderedData = [data[1] || 'n/a', data[0] || 'n/a', data[2] || 'n/a']; 

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end" gap={1} mt={4} style={{ height: "270px" }}>
      {reorderedData.map((student, index) => (
        <Tooltip
          key={index}
          title={`Total de Conclusões: ${student?.count ?? 0}`}
          placement="top"
        >
          <Paper
            elevation={index === 1 ? 6 : 3}
            sx={{
              width: 140,
              height: index === 1 ? 220 : index === 0 ? 160 : 130,
              backgroundColor: podiumColors[index],
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 2,
              cursor: "pointer",
              position: "relative",
            }}
          >
            {index === 1 && (
               <Box
                sx={{
                    position: "absolute",
                    top: 15,
                    width: 60,
                    height: 60,
                    backgroundColor: "rgba(143, 117, 117, 0.3)", 
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", 
                }}
                >
                <img 
                    src="/images/troph.png"
                    alt="Troféu"
                    style={{
                    position: "absolute",
                    top: 10,
                    width: 45,
                    height: 45,
                    }}
                />
              </Box>
            )}

            <Typography variant="h6" >
              {student?.count ?? 0}
            </Typography>
            <Typography variant="body2">{student?.studentName ?? "N/A"}</Typography>
          </Paper>
        </Tooltip>
      ))}
    </Box>
  );
};
