import querystring from 'querystring';
import api from '../../api';

class DataServiceReportCompany {
  getCompanies(params) {
    const url = 'reports/companies?' + querystring.stringify(params);
    return api.get(url);
  }

  getUsersCompanies(params) {
    const url = 'reports/companies-users?' + querystring.stringify(params);
    return api.get(url);
  }

  getCoursesCompanies(params) {
    const url = 'reports/companies-courses?' + querystring.stringify(params);
    return api.get(url);
  }

  getTeams(params) {
    const url = 'reports/teams?' + querystring.stringify(params);
    return api.get(url);
  }

  getCategories(params) {
    const url = '/reports/categories?' + querystring.stringify(params);
    return api.get(url);
  }
  getAcademic(params) {
    const url = '/reports/academic?' + querystring.stringify(params);
    return api.get(url);
  }
  academicTeamLeader(params, id_user) {
    const url = '/reports/academic-team-leader?' + querystring.stringify(params);
    return api.get(url, {
      headers: {
        'id_user': id_user,
      },
    });
  }
  
  getAcademicToXlsx(params) {
    const url = '/reports/academicToXlsx?' + querystring.stringify(params);
    return api.get(url);
  }
  getTrailToXlsx(params) {
    const url = '/reports/trailToXlsx?' + querystring.stringify(params);
    return api.get(url);
  }  

  getAttendanceToXlsx(params) {
    const url = '/reports/attendanceToXlsx?' + querystring.stringify(params);
    return api.get(url);
  }  

  getAcademicToXlsxTeamLeader(params, id_user) {
    const url = '/reports/academicToXlsx-team-leader?' + querystring.stringify(params);
    return api.get(url, {
      headers: {
        'id_user': id_user,
      },
    });
  }  

  getLGPD(params) {
    const url = '/reports/lgpd?' + querystring.stringify(params);
    return api.get(url);
  }
  getTrailsReport(params) {
    const url = '/reports/trails?' + querystring.stringify(params);
    return api.get(url);
  }  

  getAttendanceReport(params) {
    console.log('params', params)
    const url = '/reports/attendance?' + querystring.stringify(params);
    return api.get(url);
  } 
  downloadReport(){
    api.get('/reports/downloadAcademic', {        
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `RelatorioAcademico.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
  }
  downloadReportTrail(){
    api.get('/reports/downloadTrail', {        
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `RelatorioTrilha.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
  }
  downloadReportAttendance(){
    api.get('/reports/downloadAttendance', {        
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `RelatorioPresencial.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
  }
  downloadReportTeamLeader(){
    api.get('/reports/downloadAcademic-team-leader', {        
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `RelatorioAcademicoLiderTime.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
  }

}

export default new DataServiceReportCompany();
