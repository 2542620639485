import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Avatar,
  AppBar,
  Toolbar,
  Badge,
  IconButton,
  Typography,
  MenuItem,
  Popover,
  Paper,
  List,
  ListItem,
  Divider,
  ListItemText,
  Button,
  Menu as MenuComponent,
  Fade,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationsOutlined,
  HelpOutline,
  PersonOutline,
  ExitToApp,
  Menu,
  PermIdentity,
  SchoolOutlined,
  AccountBalanceOutlined,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import { HandleLogout } from 'components/';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { tobBar } from '../../../Minimal/components/Topbar/Topbar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { notificationRequest } from 'store/modules/notification/actions';
import useWindowDimensions from 'customHooks/DimensionHook';
import Notification from '../../../../components/Notification';
import theme from 'theme';
import { signInRequest } from 'store/modules/auth/actions';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    height: '64px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  textIcon: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.0em',
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  list: {
    width: '45ch',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    padding: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  notificationTitle: {
    fontSize: 11,
    marginRight: 20,
  },
  notificationDate: {
    fontSize: 11,
    textAlign: 'right',
  },
  notificationAll: {
    textAlign: 'center',
    width: '100%',
    color: 'blue',
    textDecoration: 'underline',
  },
  itemMenuNotification: {
    cursor: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  linkLogo: {
    display: 'flex'
  },
}));

export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: '#176AE6',
    },
  },
});

const Topbar = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { open, handleDrawerToggle, onSidebarOpen, ...rest } = props;
  const {
    group,
    name,
    id,
    avatar,
    email,
    idAdminUser,
    idStudentUser,
  } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state);
  const [customization, setCustomization] = React.useState({
    background_color: '#2A2E43',
    text_color: theme.palette.text.primary,
  });
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    latests: notifications,
    unreaded: notificationsUnreaded,
  } = useSelector((state) => state.notification);

  const [anchorElDropdown, setAnchorElDropdown] = useState(null);

  const openDropdown = Boolean(anchorElDropdown);
  const notificationOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const idPop = openPop ? 'simple-popover' : undefined;

  const user = {
    name,
    // avatar: avatar?.url || `https://www.gravatar.com/avatar/${emailHash}`,
    avatar: avatar?.url || '/images/avatars/noUserSbg.png',
    group,
  };

  const handleModalOpen = useCallback(() => {
    setModalStatus(true);
  });

  const handleOpenNotifications = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  });

  const handleCloseNotifications = useCallback(() => {
    setAnchorEl(null);
  });

  const handleAllNotifications = useCallback(() => {
    history.push('/notification');
    setAnchorEl(null);
  });

  const handleClickDropdown = (event) => {
    setAnchorElDropdown(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorElDropdown(null);
  };

  const fetchNotifications = async () => {
    dispatch(notificationRequest());
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (company?.theme) {
      setCustomization(company?.theme);
    }
  }, [company]);

  const handleAdminArea = useCallback(() => {
    try {
      dispatch(
        signInRequest(email, 'any', window.location.hostname, idAdminUser)
      );
    } catch (err) {}
  }, [dispatch]);

  const handleStudentArea = useCallback(() => {
    try {
      dispatch(
        signInRequest(email, 'any', window.location.hostname, idStudentUser)
      );
    } catch (err) {}
  }, [dispatch]);

  const { width } = useWindowDimensions();

  return (
    <ThemeProvider theme={tobBar}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: customization?.background_color }}
        className={classes.appBar}
      >
        <Toolbar>
          {/* Check if is desktop layout to show the company logo */}
          {width > 600 && customization?.logo?.url?.length > 0 && (
            <RouterLink to='/' className={classes.linkLogo}>
              <img
                height={50}
                src={customization?.logo?.url}
                style={{ maxWidth: 166, objectFit: 'contain', maxHeight: 40 }}
              ></img>
            </RouterLink>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu style={{ color: customization?.text_color }} />
          </IconButton>
          <div className={classes.flexGrow} />
          <a
            style={{ textDecoration: 'none', color: 'inherit' }}
            target="_blank"
            href={window._env_.REACT_APP_TAWK_HELP_URL}
          >
            <MenuItem>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <HelpOutline style={{ color: customization?.text_color }} />
              </IconButton>
              <p
                style={{ color: customization?.text_color }}
                className={classes.textIcon}
                variant="caption"
              >
                Ajuda
              </p>
            </MenuItem>
          </a>

          <MenuItem onClick={handleClick}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              {notificationsUnreaded > 0 && (
                <Badge badgeContent={3} color="secondary" variant="dot">
                  <NotificationsOutlined
                    style={{ color: customization?.text_color }}
                  />
                </Badge>
              )}

              {notificationsUnreaded <= 0 && (
                <NotificationsOutlined
                  style={{ color: customization?.text_color }}
                />
              )}
            </IconButton>

            <p
              style={{ color: customization?.text_color }}
              className={classes.textIcon}
              variant="caption"
            >
              Notificações
            </p>
          </MenuItem>

          <MenuComponent
            id="notification-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={notificationOpen}
            onClose={handleCloseNotifications}
          >
            <MenuItem
              className={classes.itemMenuNotification}
              onClick={handleAllNotifications}
            >
              <Notification />
            </MenuItem>
          </MenuComponent>

          {/* TODO Criar DROPDOWN com as opções */}
          <MenuItem>
            <IconButton
              onClick={handleClickDropdown}
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
            >
              <Avatar
                alt="Person"
                src={
                  avatar?.url || '/images/avatars/noUserSbg.png'
                }
              />
            </IconButton>
            <p
              style={{ color: customization?.text_color }}
              onClick={handleClickDropdown}
              className={classes.textIcon}
            >
              {name}
            </p>
            <ExpandMoreOutlined
              style={{ color: customization?.text_color }}
              onClick={handleClickDropdown}
              aria-controls="fade-menu"
              aria-haspopup="true"
            ></ExpandMoreOutlined>
          </MenuItem>
          <div>
            <MenuComponent
              id="fade-menu"
              anchorEl={anchorElDropdown}
              keepMounted
              open={openDropdown}
              onClose={handleCloseDropdown}
              TransitionComponent={Fade}
            >
              <span onClick={handleCloseDropdown}>
                {idAdminUser && (
                  <MenuItem onClick={handleAdminArea}>
                    <span className={classes.dropDownSpan}>
                      <IconButton aria-label="show 4 new mails" color="inherit">
                        <AccountBalanceOutlined fontSize="small" />
                      </IconButton>
                      Admin
                    </span>
                  </MenuItem>
                )}
                {idStudentUser && (
                  <MenuItem onClick={handleStudentArea}>
                    <span className={classes.dropDownSpan}>
                      <IconButton aria-label="show 4 new mails" color="inherit">
                        <SchoolOutlined fontSize="small" />
                      </IconButton>
                      Aluno
                    </span>
                  </MenuItem>
                )}
              </span>
              <MenuItem onClick={handleCloseDropdown}>
                <span
                  className={classes.dropDownSpan}
                  onClick={() => history.push(`/users/edit-profile`)}
                >
                  <IconButton aria-label="show 4 new mails" color="inherit">
                    <PermIdentity fontSize="small" />
                  </IconButton>
                  Editar Perfil
                </span>
              </MenuItem>
              <MenuItem>
                <span
                  className={classes.dropDownSpan}
                  onClick={handleModalOpen}
                >
                  <IconButton aria-label="logout">
                    <ExitToApp fontSize="small" className={classes.iconColor} />
                  </IconButton>
                  Sair da Plataforma
                </span>
              </MenuItem>
            </MenuComponent>
          </div>
        </Toolbar>
        <HandleLogout closeModal={setModalStatus} isOpen={modalStatus} />
      </AppBar>
    </ThemeProvider>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
