import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportAcademicTeamLeaderTable from '../Table';
import ReportAcademicTeamLeaderDashboard from '../Dashboard';
import CustomTabComponent from '../../../../../components/CustomTab/index';
import {Grid} from '@material-ui/core'


import { useStyles } from './styles';

export default ({ fields }) => {
  const classes = useStyles();
    const [paginationNumber, setPaginationNumber] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    
   const { t } = useTranslation([
      'title',
      'form',
      'button',
      'validation',
      'toastify',
      'label',
    ]);

  const tabComponents = [
      {
        label: t('button:User.btn_profile_team'),
        component: <ReportAcademicTeamLeaderTable fields={fields} />
      },
      {
        label: t('button:User.btn_profile_dashaboard_team'),
        component: <ReportAcademicTeamLeaderDashboard />
      }, 
    ];

  return (
    <div className={classes.root}>
      <div className={classes.topButtonContainer}>
        <Grid item xs={12}>
          <CustomTabComponent
            components={tabComponents}
            valueReturn={(value) => setPaginationNumber(value)}
          />
        </Grid>
      </div>
    </div>
    );
};

class TabValues {
  static Teams = 1;
  static Dashboard = 0;
}