import api from 'services/api';

class LessonQuestionsData {
  getlessonQuestions(id_user, lesson_content_id) {
    return api.get(`lesson-content-exams/${id_user}/${lesson_content_id}`);
  }
  updateLessonQuestions(id_user, lesson_content_id, lessons) {
    return api.put(
      `/lesson-content-exams/${id_user}/${lesson_content_id}`,
      lessons
    );
  }

  sendExamResult(
    id_user,
    lesson_content_id,
    lesson_content_exams,
    availableExamAttempts
  ) {
    return api.post(
      `/lesson-content-exams/student/${id_user}/finish/${lesson_content_id}`,
      { available_exam_attempts: availableExamAttempts, lesson_content_exams }
    );
  }

  checkApprovedExam(id_lesson_content, id_user, id_course) {
    return api.get(`/check-is-approved/course/${id_course}/lesson-content/${id_lesson_content}/user/${id_user}`);
  }

  getExamResult(id_user, lesson_content_id) {
    return api.get(`/lesson-content-exams/student/${id_user}/result/${lesson_content_id}`);
  }

  getExamDetails(id_user, lesson_content_id) {
    return api.get(
      `/lesson-content-exams/student/${id_user}/exam-details/${lesson_content_id}`
    );
  }

  getPendingClasses(id_user, id_course, lesson_id = '') {
    return api.get(`lesson-content-exams-class-pending/student/${id_user}/course/${id_course}`, { params: { lesson_id } });
  }

  getWorkloadExam(id_user, id_course) {
    return api.get(`lesson-content-exams/student/${id_user}/workload-exam/${id_course}`);
  }
}

export default new LessonQuestionsData();
