import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { Pie } from "react-chartjs-2";

export function PieChart({ data = {} }) {
  const formattedData = useMemo(() => {
    return {
      labels: ["Aprovado", "Não aprovado", "Não iniciado", "Em andamento"],
      datasets: [
        {
          data: [
            data.aprovado || 0,
            data.naoAprovado || 0,
            data.naoIniciado || 0,
            data.andamento || 0,
          ],
          backgroundColor: ["#4CAF50", "#F44336", "#FF9800", "#2196F3"],
          borderColor: ["#fff"],
        },
      ],
    };
  }, [data]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "right",
      labels: {
        usePointStyle: true,
        fontSize: 12,
        padding: 10,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let index = tooltipItem.index;
          return `${data.labels[index]}: ${dataset.data[index]}`;
        },
      },
    },
  };

  const drawLabelsPlugin = {
    id: "drawLabels",
    afterDraw: function (chart) {
      let ctx = chart.ctx;
      ctx.font = "bold 14px Arial";
      ctx.fillStyle = "#fff"; 
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      chart.data.datasets.forEach((dataset, i) => {
        let meta = chart.getDatasetMeta(i);
        meta.data.forEach((element, index) => {
          let value = dataset.data[index]; 
          let position = element.tooltipPosition(); 
          if (value > 0) {
            ctx.fillText(value, position.x, position.y); 
          }
        });
      });
    },
  };

  return (
    <Grid container item md={12} xs={12}>
      <div style={{ width: "100%", height: "300px" }}>
        <Pie data={formattedData} options={options} plugins={[drawLabelsPlugin]} />
      </div>
    </Grid>
  );
}
