import React from 'react';
import { useStyles } from '../styles';
import { Notifications } from '@material-ui/icons';
// import Notification from 'components/Notification';

const GamificationNotifications = () => {

    const classes = useStyles();

    return (
        <div className={classes.rankingTabContainer}>
            <div className={classes.rankingAdminTitle}>
                <Notifications />
                <h5>Notificações</h5>
            </div>
            <div>
                
            </div>
        </div>
    )
}

export default GamificationNotifications;