const notificationTemplates = {
    pointsEarned: ({ name, qtdPoints, coinName, transactionType, email }) => ({
        title: transactionType === "CREDIT" ? "Você ganhou pontos extras!" : "Você perdeu pontos!",
        content: transactionType === "CREDIT" ? `<p>Olá, <strong>${name}!</strong></p> <br /> <p>Boa notícia! Você recebeu <strong>${qtdPoints} ${coinName}</strong>.</p> <br /> <p>Confira agora seu saldo atualizado na plataforma e veja suas opções na loja de recompensas!</p> <br /> <p>Continue aproveitando nossos benefícios.</p> <br /> <p>Abraços,</p> <br /> <p>Equipe Cresça Mais</p>` : `<p>Olá, <strong>${name}!</strong></p> <br /> <p>Você perdeu <strong>${qtdPoints} ${coinName}</strong>.</p> <br /> <p>Confira agora seu saldo atualizado na plataforma e veja suas opções na loja de recompensas!</p> <br /> <p>Abraços,</p> <br /> <p>Equipe Cresça Mais</p>`,
        email: email,
    }),
    newRewards: ({ name, email }) => ({
        title: "Novidades na loja de recompensas!",
        content: `<p>Olá, <strong>${name}!</strong></p><br /> <p>Temos novidades incríveis para você! Acabamos de adicionar novos prêmios à loja de recompensas.</p> <p>Não perca tempo, confira agora e escolha seu favorito.</p> <br /> <p>Esperamos que você goste das novidades!</p> <br /> <p>Abraços.</p> <br /> <p>Equipe Cresça Mais</p>`,
        email: email,
    }), 
    rescueCompletedStudent: ({ name, item, email }) => ({
        title: "Seu resgate foi um sucesso!",
        content: `<p>Olá, <strong>${name}!</strong></p> <br /> <p>Uau! Você resgatou <strong>${item}</strong>.</p><br /> <p>Verifique seus e-mails ou acesse a plataforma para mais instruções sobre como retirar sua recompensa</p> <br /> Parabéns por sua evolução! <br /> Abraços. <br /> Equipe Cresça Mais`,
        email: email,
    }),
    rescueCompletedAdmin: ({ name, item, email }) => ({
        title: "Resgate de recompensa realizado!",
        content: `<p>Olá, <strong>${name}!</strong></p> <br /> <p>O aluno <strong>${name}</strong> resgatou <strong>${item}</strong> na loja.</p> <br /> <p>Confira os detalhes na plataforma!</p> <br /> <p>Abraços.</p> <br /> <p>Equipe Cresça Mais</p>`,
        email: email,
    }),
    finishCourse: ({ name, courseName, qtdPoints, coinName, email }) => ({
        title: "Parabéns pela conquista, " + name,
        content: `<p>Olá, <strong>${name}!</strong></p><br /> <p>Parabéns! Você concluiu o curso <strong>${courseName}</strong> e ganhou <strong>${qtdPoints} ${coinName}</strong>!</p> <p>Acesse a loja agora e veja o que você pode conquistar com sua pontuação.</p> <br /> <p>Abraços.</p> <br /> <p>Equipe Cresça Mais</p>`,
        email: email,
    }),
};

export default notificationTemplates;
