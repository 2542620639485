import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  gridMargin: {
    marginRight: 100,
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  icon: {
    marginRight: theme.spacing(1)
  },

  message: {
    width: '100%', 
    position: 'absolute', 
    zIndex: 999, 
    left: '50%', 
    transform: 'translateX(22%)'
   },
   greenIcon: {
    backgroundColor: '#2DCE98',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C',
  },

  blueIcon: {
    backgroundColor: '#176AE6',
  },
  
  yellowIcon: {
    backgroundColor: '#f0c24e',
  },

  redIcon: {
    backgroundColor: '#F53C56',
  },
}));