import React, { useState, useRef } from 'react';
import { useStyles } from './styles';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import {
  CourseData,
  CategoryData,
  SubCategoryData,
  UserData,
} from 'services/dataService';
import { useHistory, useParams } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import NewTeacher from 'components/NewTeacher';
import ButtonsAction from 'components/ButtonsAction';
import CustomTabComponent from 'components/CustomTab';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [idCategory, setIdCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [idSubCategory, setIdSubCategory] = useState('');
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    watch,
  } = useForm();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'tables',
  ]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [keywordsOptions, setKeywordsOptions] = useState([]);
  const [descripion, setDescription] = useState();
  const [score, setScore] = useState();
  const [content, setContent] = useState();
  const watchCategory = watch('id_category');
  const [tabValue, setTabValue] = useState(0);
  const [objectives, setObjectives] = useState();
  const [targetAudience, setTargetAudience] = useState();
  const [methodology, setMethodology] = useState();
  const [certificate, setCertificate] = useState();
  const [owner, setOwner] = useState(false);
  const [text, setText] = useState('');
  const [formattedWorkload, setFormattedWorkload] = useState();
  const [teacherCombo, setTeacherCombo] = React.useState([]);
  const [teacherData, setTeacherData] = React.useState({
    teacher_id: { id: '', name: '' },
    image_id: '',
    image_url: '',
    teacher_description: '',
  });
  const handleTabChange = (event, newValue) => {
    if (newValue == tabValue) return;
    setTabValue(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === '/' || event.key === '\\') {
      event.preventDefault();
    }
  }


  const handleChangeScore = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      this.setState({ value: onlyNums });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      this.setState({ value: number });
    }
  };

  const getCourseTeacher = async () => {
    const { users: teachers } = (
      await UserData.getUsers({
        id_group: '7c656a2d-862f-46d7-928c-b84599faee61',
      })
    ).data;
    setTeacherCombo(
      teachers?.map(({ id, name }) => {
        return { id, name };
      })
    );
  };

  const getTeacher = async () => {
    const { data: teacher } = await CourseData.getCourseAuthorByCourseId(id);

    setTeacherData({
      teacher_description: teacher?.User?.teacher_description,
      image_url: teacher?.User?.avatar?.url_thumbnail,
      image_id: teacher?.User?.avatar?.id,
      teacher_id: {
        id: teacher?.User?.id,
        name: teacher?.User?.name,
      },
    });
  };

  const convertWorkloadToDecimal = (workload) => {
    if (!workload) return null;
    
    const [hours, minutes] = workload.split(':').map(Number);
    return +(hours + minutes / 60).toFixed(4);
  };

  const onSubmit = (data) => {
    const keywordsText = keywords.toString();
    const descriptionText = descripion
      ? draftToHtml(convertToRaw(descripion.getCurrentContent()))
      : '';

    const contentText = content
      ? draftToHtml(convertToRaw(content.getCurrentContent()))
      : '';

    const objectivesText = objectives
      ? draftToHtml(convertToRaw(objectives.getCurrentContent()))
      : '';

    const targetAudienceText = targetAudience
      ? draftToHtml(convertToRaw(targetAudience.getCurrentContent()))
      : '';

    const methodologyText = methodology
      ? draftToHtml(convertToRaw(methodology.getCurrentContent()))
      : '';

    const certificateText = certificate
      ? draftToHtml(convertToRaw(certificate.getCurrentContent()))
      : '';

    const priceFormated = data?.price?.replace(',', '.');

    const workloadDecimal = convertWorkloadToDecimal(data.workload);

    const formValue = {
      name: data.name,
      keywords: keywordsText,
      content: contentText,
      description: descriptionText,
      id_category: data.id_category,
      id_subcategory: data.id_subcategory,
      score: data.score,
      workload: workloadDecimal,
      objectives: objectivesText,
      target_audience: targetAudienceText,
      methodology: methodologyText,
      certificate: certificateText,
      price: priceFormated,
      id_course_author: data?.course_teacher?.teacher_user_id,
      ...courseData,
    };

    (async () => {
      try {
        await CourseData.updateCourse(id, formValue);
        toast.success(t('toastify:commons.toast_update'));
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    })();
  };

  useEffect(() => {
    if (typeof watchCategory === 'string') handleChange(watchCategory);
  }, [watchCategory]);

  useEffect(() => {
    getCourseTeacher();
    getTeacher();
  }, []);

  const handleChange = async (idCategory) => {
    try {
      const categoryName = categories.filter(
        (elem) => elem.id === idCategory
      )[0].name;

      const response = await SubCategoryData.getSubCategories({
        category: categoryName,
      });
      setSubCategories(response.data.subCategories);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCourses({ id_course: id });
        const companyCourse = response.data.courses[0];

        const responseCategories = await CategoryData.getCategories();
        setCategories(responseCategories.data.categories);
        const categoryName = responseCategories.data.categories.filter(
          (elem) => elem.id === companyCourse.id_category
        )[0].name;

        if (categoryName) {
          const responseSubCategories = await SubCategoryData.getSubCategories({
            category: categoryName,
          });
          setSubCategories(responseSubCategories.data.subCategories);
        }

        const {
          name,
          keywords,
          content,
          description,
          score,
          workload,
          objectives,
          target_audience,
          methodology,
          certificate,
        } = companyCourse.course;

        const { price } = companyCourse;

        const { id_category, id_subcategory, is_owner } = companyCourse;
        setOwner(is_owner);

        if (description) {
          const blocksFromHtml = htmlToDraft(description);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          setDescription(editorState);
        }

        if (content) {
          const blocksFromHtml = htmlToDraft(content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          setContent(editorState);
        }

        if (objectives) {
          const blocksFromHtml = htmlToDraft(objectives);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const objectivesState = EditorState.createWithContent(contentState);
          setObjectives(objectivesState);
        }

        if (target_audience) {
          const blocksFromHtml = htmlToDraft(target_audience);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const targetAudienceState = EditorState.createWithContent(
            contentState
          );
          setTargetAudience(targetAudienceState);
        }

        if (methodology) {
          const blocksFromHtml = htmlToDraft(methodology);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const methodologyState = EditorState.createWithContent(contentState);
          setMethodology(methodologyState);
        }

        if (certificate) {
          const blocksFromHtml = htmlToDraft(certificate);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const certificateState = EditorState.createWithContent(contentState);
          setCertificate(certificateState);
        }

        if (keywords) {
          const keywordsText = keywords.split(',');
          setKeywords(keywordsText);
        }

        setIdCategory(id_category);
        setIdSubCategory(id_subcategory);

        const convertDecimalToWorkloadd = (workloadDecimal) => {
          const hours = Math.floor(workloadDecimal);
          const minutes = Math.round((workloadDecimal - hours) * 60);
          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        };

        const formWorkload = convertDecimalToWorkloadd(workload);

        setFormattedWorkload(formWorkload)

        setValue([
          { name },
          { content },
          { id_category },
          { id_subcategory },
          { workload: formWorkload },
          { score },
          {
            price: Intl.NumberFormat('pt-br', {
              minimumFractionDigits: 2,
            }).format(price),
          },
        ]);

        setCourseData({
          signable: companyCourse.signable,
          require_classes_completion: companyCourse.require_classes_completion,
          require_classes_approval: companyCourse.require_classes_approval,
          id_file_banner: companyCourse.id_file_banner,
          id_file_card: companyCourse.id_file_card,
        });
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();

    setLoading(false);
  }, []);
  

  if (!loading)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.root}>
          <Grid container spacing={3}>
            <Grid item md={8} xs={12} className={classes.columnGrid}>
              <TextField
                fullWidth
                label={t('form:Courses.Setting.title_course')}
                helperText={
                  errors.title?.type === 'required' &&
                  t('validation:commons.validation_required', {
                    field: t('form:Courses.Setting.title_course'),
                  })
                }
                onChange={(e) => setText(e.target.value)}
                onKeyPress={handleKeyPress}
                name="name"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register({ required: true })}
                error={!!errors.title}
                disabled={!owner}
              />
            </Grid>
            <Grid item md={2} xs={12} className={classes.columnGrid}>
            <TextField
              fullWidth
              label={t('form:Courses.Setting.hour_course')}
              helperText={
                errors.workload?.type === 'required' &&
                t('validation:commons.validation_required', {
                  field: t('form:Courses.Setting.hour_course'),
                })
              }
              name="workload"
              type="text"
              variant="outlined"
              placeholder="00:00"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: true })}
              value={formattedWorkload}
              onChange={(e) => {
                setFormattedWorkload(e.target.value);
              }}
              InputProps={{
                inputComponent: InputMask,
                inputProps: {
                  mask: '99:99',
                  maskChar: null,
                },
              }}
              error={!!errors.workload}
              disabled={!owner}
            />
          </Grid>
          <Grid item md={2} xs={12} className={classes.columnGrid}>
            <TextField
              fullWidth
              label={t('form:Courses.Setting.score')}
              helperText={
                errors.score?.type === 'required' &&
                t('validation:commons.validation_required', {
                  field: t('form:Courses.Setting.score'),
                })
              }
              name="score"
              type="number"
              variant="outlined"
              placeholder='0 a 100'
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: true })}
              error={!!errors.score}
              disabled={!owner}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12} className={classes.columnGrid}>
            <FormControl fullWidth>
              <InputLabel id="id_category">
                {t('form:Courses.Setting.category_course')}
              </InputLabel>

              <Controller
                as={
                  <Select>
                    {categories.length > 0 &&
                      categories.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="id_category"
                control={control}
                defaultValue={{ idCategory }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12} className={classes.columnGrid}>
            <FormControl fullWidth>
              <InputLabel id="id_subcategory">
                {t('form:Courses.Setting.sub_category_course')}
              </InputLabel>

              <Controller
                as={
                  <Select>
                    {subCategories.length > 0 &&
                      subCategories.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                }
                name="id_subcategory"
                control={control}
                defaultValue={{ idSubCategory }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.columnGrid}>
            <Typography color="textSecondary" gutterBottom variant="body2">
              {t('form:Courses.Setting.description_course')}
            </Typography>
            <Editor
              readOnly={!owner}
              editorStyle={{
                border: '1px solid #C0C0C0',
                borderRadius: '4px',
                height: '17rem',
              }}
              editorState={descripion}
              toolbarClassName="rdw-storybook-toolbar"
              wrapperClassName="rdw-storybook-wrapper"
              editorClassName="rdw-storybook-editor"
              onEditorStateChange={(vl) => setDescription(vl)}
              toolbar={{
                options: ['inline', 'list', 'remove', 'history'],
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.columnGrid}>
            <CustomTabComponent
              components={[
                {
                  label: t('tables:Tabs.objectives_general'),
                  component: (
                    <Editor
                      readOnly={!owner}
                      editorStyle={{
                        border: '1px solid #C0C0C0',
                        borderRadius: '4px',
                        height: '17rem',
                      }}
                      editorState={objectives}
                      toolbarClassName="rdw-storybook-toolbar"
                      wrapperClassName="rdw-storybook-wrapper"
                      editorClassName="rdw-storybook-editor"
                      onEditorStateChange={(vl) => setObjectives(vl)}
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'remove',
                          'history',
                        ],
                      }}
                    />
                  ),
                },
                {
                  label: t('tables:Tabs.teacher'),
                  component: (
                    <Box mt={2} maxWidth={620}>
                      <NewTeacher
                        isSelect
                        read_only
                        teacher_id={teacherData?.teacher_id}
                        image_id={teacherData?.image_id}
                        image_url={teacherData?.image_url}
                        teacher_description={teacherData?.teacher_description}
                        selectFields={teacherCombo || []}
                        register={register}
                        readOnlyOnTeacherSelected={async (id) => {
                          const {
                            data: courseAuthor,
                          } = await UserData.getUserAuthor(id);
                          return courseAuthor;
                        }}
                        name="course_teacher"
                      />
                    </Box>
                  ),
                },
                {
                  label: t('tables:Tabs.target_audience_general'),
                  component: (
                    <Editor
                      readOnly={!owner}
                      editorStyle={{
                        border: '1px solid #C0C0C0',
                        borderRadius: '4px',
                        height: '17rem',
                      }}
                      editorState={targetAudience}
                      toolbarClassName="rdw-storybook-toolbar"
                      wrapperClassName="rdw-storybook-wrapper"
                      editorClassName="rdw-storybook-editor"
                      onEditorStateChange={(vl) => setTargetAudience(vl)}
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'remove',
                          'history',
                        ],
                      }}
                    />
                  ),
                },
                {
                  label: t('tables:Tabs.methodology_general'),
                  component: (
                    <Editor
                      readOnly={!owner}
                      editorStyle={{
                        border: '1px solid #C0C0C0',
                        borderRadius: '4px',
                        height: '17rem',
                      }}
                      editorState={methodology}
                      toolbarClassName="rdw-storybook-toolbar"
                      wrapperClassName="rdw-storybook-wrapper"
                      editorClassName="rdw-storybook-editor"
                      onEditorStateChange={(vl) => setMethodology(vl)}
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'remove',
                          'history',
                        ],
                      }}
                    />
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>

        <ButtonsAction
          isUpdate
          cancelBtn={{
            handleCancelClick: () => history.push(`/courses/${id}/lessons`),
            text: t('button:FeaturedCourses.cancel'),
          }}
          saveBtn={{
            text: t('button:FeaturedCourses.update'),
            color: 'secondary',
          }}
        />
      </Paper>
      </form >
    );
  return <></>;
};
