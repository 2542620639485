import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import theme from 'theme';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    alignItems: 'center',
  },
  logo: { maxWidth: 166, objectFit: 'contain', maxHeight: 40 },
  linkLogo: {
    display: 'flex'
  },
}));

export const tobBar = createTheme({
  palette: {
    primary: {
      main: '#2A2E43',
    },
  },
});

const Topbar = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const { company } = useSelector((state) => state);
  const [customization, setCustomization] = React.useState({
    background_color: '#2A2E43',
    text_color: theme.palette.text.primary,
  });

  const loadTopbarStyles = async () => {
    try {
      const {
        data: customization,
      } = await PublicCoursesViewsData.getCompanyCustomization(
        company?.domain_company
      );
      setCustomization(customization?.company_header);
    } catch (error) {}
  };

  React.useEffect(() => {
    loadTopbarStyles();
  }, []);

  return (
    <ThemeProvider theme={tobBar}>
      <AppBar
        {...rest}
        style={{ backgroundColor: customization?.background_color }}
        className={clsx(classes.root, className)}
        position="fixed"
      >
        <Toolbar>
          <RouterLink to="/" className={classes.linkLogo}>
            <img
              alt="Logo"
              src={customization?.logo?.url}
              className={classes.logo}
            />
          </RouterLink>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
