import querystring from 'querystring';
import api from '../api';

class DataServiceCourse {
  getCourseAuthorByCourseId(id) {
    return api.get(`course/author/${id}`);
  }

  addCourse(data, id_group) {
    return api.post(`courses?domain=${window.location.hostname}`, data);
  }

  getCourses(params) {
    const url = 'company-course?' + querystring.stringify(params);
    return api.get(url);
  }

  getClassroomCourses(params) {
    const url = 'company-course-classroom?' + querystring.stringify(params);
    return api.get(url);
  }

  getCountCoursesManagement() {
    return api.get('courses/count-courses-management');
  }


  getCountCoursesManagementAttendance() {
    return api.get('courses/count-courses-management-attendance');
  }

  getFeaturedCourse() {
    return api.get('featured-courses/internal');
  }

  getCountCourses(headers) {
    return api.get('company-course/count', headers);
  }

  getCoursesModules(headers) {
    return api.get('company-course/all-courses', headers);
  }

  getCourseById(id) {
    return api.get(`company-course?id_course=${id}`);
  }

  updateCourse(id, data) {
    return api.put(`courses/${id}`, data);
  }

  duplicateCourse(courses) {
    return api.post(`courses/${courses}/duplicate`);
  }

  getRank(params) {
    const url = 'company-course/rank?'+ querystring.stringify(params); ;
    return api.get(url);
  }

  getCompanyCourseByCourse(id_course) {
    return api.get(`company-course/course/${id_course}`);
  }

  getCompanyCourseById(id) {
    return api.get(`company-course/payment/${id}`);
  }

  getCompanyCourseCertificateType(company_course_id) {
    return api.get(`/company-course/${company_course_id}/certificate-type`);
  }

  setcompanyCertificateType(company_course_id, type) {
    return api.put(`/company-course/${company_course_id}/certificate-type`, {
      type: type,
    });
  }

  getStudentAvalibleContent(id) {
    return api.get(`company-course/student-content/${id}`);
  }

  getCourseInProgress(id_user, ignore_finished = true) {
    return api.get(
      `/company-course/progress/${id_user}?ignore_finished=${ignore_finished}`
    );
  }

  getMyContentCourses(id_user) {
    return api.get(`student-courses/${id_user}`);
  }

  // TODO: Melhorar este cara
  updateCompanyCourse(id_company_course, data) {
    return api.put(`/company-course/${id_company_course}`, data, {params: {id: id_company_course}});
  }
}

export default new DataServiceCourse();
