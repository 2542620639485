import React, { useState } from 'react';
import { useStyles } from './styles';
import { Paper, InputLabel, Switch, Button, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CourseData } from 'services/dataService';
import InputMask from 'react-input-mask';
import { useHistory, useParams } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import ButtonsAction from 'components/ButtonsAction';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [signableSwitch, setSignableSwitch] = useState(false);
  const [visibleSwitch, setVisibleSwitch] = useState(false);
  const [showExamSwitch, setShowExamSwitch] = useState(false);
  const [formattedWorkload, setFormattedWorkload] = useState();
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState({});
  const { id } = useParams();


  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const handleSignableChange = () => {
    setSignableSwitch((prev) => !prev);
  };

  const handleVisibleChange = () => {
    setVisibleSwitch((prev) => !prev);
  };

  const handleShowExamChange = () => {
    setShowExamSwitch((prev) => !prev);
  };

  const onSubmit = (data) => {

    const convertWorkloadToDecimal = (workload) => {
      if (!workload) return null;
      
      const [hours, minutes] = workload.split(':').map(Number);
      return +(hours + minutes / 60).toFixed(4);
    };

    console.log('data', data)
    const workloadDecimal = convertWorkloadToDecimal(data.workload_exam);

    const formValue = {
      signable: data.signable,
      visible: !data.visible,
      show_exam: data.show_exam,
      workload_exam: workloadDecimal,
      ...courseData,
    };

    (async () => {
      try {
        await CourseData.updateCourse(id, formValue);
        toast.success(t('toastify:commons.toast_update'));
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    })();
  };


  useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCourses({ id_course: id });
        const companyCourse = response.data.courses[0];

        const convertDecimalToWorkload = (workloadDecimal) => {
          const hours = Math.floor(workloadDecimal);
          const minutes = Math.round((workloadDecimal - hours) * 60);
          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        };

        const formWorkload = convertDecimalToWorkload(companyCourse.workload_exam);
        setFormattedWorkload(formWorkload)

        setSignableSwitch(companyCourse.signable);
        setValue([{ signable: companyCourse.signable }]);
        setVisibleSwitch(companyCourse.visible);
        setValue([{visible: companyCourse.visible}]);
        setShowExamSwitch(companyCourse.show_exam);
        setValue([{show_exam: companyCourse.show_exam}]);
        setValue([{workload_exam: formWorkload}])

        setCourseData({
          name: companyCourse.course.name,
          keywords: companyCourse.course.keywords,
          content: companyCourse.course.content,
          description: companyCourse.course.description,
          id_category: companyCourse.id_category,
          id_subcategory: companyCourse.id_subcategory,
          score: companyCourse.course.score,
          workload: companyCourse.course.workload,
          require_classes_completion: companyCourse.require_classes_completion,
          require_classes_approval: companyCourse.require_classes_approval,
          id_file_banner: companyCourse.id_file_banner,
          id_file_card: companyCourse.id_file_card,
        });
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();
    setLoading(false);
  }, []);

  if (!loading)
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="signable">
                {t('form:Courses.Setting.sign_course')}
              </InputLabel>
              <Switch
                id="signable"
                checked={signableSwitch}
                onChange={handleSignableChange}
                color="primary"
                inputRef={register}
                name="signable"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="visible">
                {t('form:Courses.Setting.hide_course')}
              </InputLabel>
              <Switch
                id="visible"
                checked={!visibleSwitch}
                onChange={handleVisibleChange}
                color="primary"
                inputRef={register}
                name="visible"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel id="show_exam">
                {t('form:Courses.Setting.show_exam')}
              </InputLabel>
                <Tooltip title={"Ativando esta opção, o aluno só poderá realizar a avaliação após cumprir a carga horária mínima exigida."}>
                  <Switch
                    id="show_exam"
                    checked={showExamSwitch}
                    onChange={handleShowExamChange}
                    color="primary"
                    inputRef={register}
                    name="show_exam"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </Tooltip>
              {showExamSwitch && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Carga horária diária"
                    placeholder="00:00"
                    name="workload_exam"
                    value={formattedWorkload}
                    style={{marginTop: '5px'}}
                    onChange={(e) => {
                      setFormattedWorkload(e.target.value);
                    }}
                    InputProps={{
                      inputComponent: InputMask,
                      inputProps: {
                        mask: '99:99',
                        maskChar: null,
                      },
                    }}
                    inputRef={register}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ButtonsAction
                isUpdate
                cancelBtn={{
                  handleCancelClick: () =>
                    history.push(`/courses/${id}/lessons`),
                  text: t('button:FeaturedCourses.cancel'),
                }}
                saveBtn={{
                  text: t('button:FeaturedCourses.update'),
                  color: 'secondary',
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  return <></>;
};
