import React, { useState, useRef, useCallback, useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VerticalAlignBottom, Search, AccessTime, Class, AssignmentTurnedInOutlined, SchoolOutlined } from '@material-ui/icons';
import { useStyles } from './styles';
import { ReportCompanyData } from 'services/dataService';
import { useForm, Controller } from 'react-hook-form';
import { ChartCard } from './types/Cards/ChartCard';
import { PieChart } from './types/PieChart';
import { TopUsersChart } from './types/BarChart';
import {Podium } from './types/Podium'
import { Card } from 'components/Toolbar';
import { Grid, Button, TextField} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default ({ fields }) => {
  const classes = useStyles();
  const [indicators, setIndicators] = useState(null);
  const [courses, setCourses] = useState(null);
  const [filter, setFilter] = useState('');
  const [topStudents, setTopStudents] = useState(null);
  const [mockData, setMockData] = useState({
    aprovado: 0,
    naoAprovado: 0,
    andamento: 0,
    naoIniciado: 0,
  });

  const { id } = useSelector((state) => state.auth);

  const { t } = useTranslation([
      'label',
      'toastify',
      'tables',
      'form',
      'button',
      'validation',
      'cards'
    ]);

  const { register, handleSubmit, control } = useForm();
  const onSubmit = (data) => {
    setFilter(data.filter);
  };


  const fetchIndicators = async () => {
    try {
      const response = await ReportCompanyData.academicTeamLeader({filter}, id);
      
      const statusCounts = response.data.academic.reduce((acc, indicator) => {
        switch (indicator.approved) {
          case "Aprovado": acc.aprovado += 1; break;
          case "Não aprovado": acc.naoAprovado += 1; break;
          case "Em andamento": acc.andamento += 1; break;
          case "Não iniciado": acc.naoIniciado += 1; break;
          default: break;
        }
        return acc;
      }, { aprovado: 0, naoAprovado: 0, andamento: 0, naoIniciado: 0 });

      const concludedCourses = response.data.academic.filter(
        (course) => course.approved === "Aprovado"
      );
  
      const courseCounts = concludedCourses.reduce((acc, course) => {
        acc[course.course_name] = (acc[course.course_name] || 0) + 1;
        return acc;
      }, {});
  
      const sortedCourses = Object.entries(courseCounts)
        .sort(([, countA], [, countB]) => countB - countA) 
        .slice(0, 3);
  
      const topCourses = sortedCourses.map(([courseName, count]) => ({
        courseName,
        count,
      }));

      const concludedStudents = response.data.academic.filter(
        (record) => record.approved === "Aprovado"
      );
      
      const studentCounts = concludedStudents.reduce((acc, record) => {
        acc[record.student_name] = (acc[record.student_name] || 0) + 1;
        return acc;
      }, {});
      
      const sortedStudents = Object.entries(studentCounts)
        .sort(([, countA], [, countB]) => countB - countA)
        .slice(0, 3);
      
      const topStudents = sortedStudents.map(([studentName, count]) => ({
        studentName,
        count,
      }));

      setTopStudents(topStudents);
      setCourses(topCourses)
      setMockData(statusCounts)
      setIndicators(response.data.indicators);
    } catch (err) {
      toast.error("Erro ao buscar indicadores.");
    }
  };


  useEffect(() => {
    fetchIndicators();
  }, [filter]);
  
  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{padding: '10px', marginTop: '0px'}}>
        <Grid container spacing={1} md={12} >
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
          <Grid item md={8} xs={12} >
            <TextField
              fullWidth
              label={'Pesquisar alunos'}
              name="filter"
              type="text"
              variant="outlined"
              defaultValue={filter || ''}
              style={{marginLeft: "3px"}}
              inputRef={register}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}>
            <Grid item md={2} xs={12}>
              <FormControl style={{ minWidth: 130 }}>
                <InputLabel id="status3">
                  {t("form:commons.input_period_finished")}
                </InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value={1}>{t(statusPeriodFinished[1]?.title)}</MenuItem>
                      <MenuItem value={2}>{t(statusPeriodFinished[2]?.title)}</MenuItem>
                      <MenuItem value={3}>{t(statusPeriodFinished[3]?.title)}</MenuItem>
                      <MenuItem value={5}>{t(statusPeriodFinished[5]?.title)}</MenuItem>
                      <MenuItem value={6}>{t(statusPeriodFinished[6]?.title)}</MenuItem>
                      <MenuItem value={4}>{t(statusPeriodFinished[4]?.title)}</MenuItem>
                    </Select>
                  }
                  name="periodFinished"
                  control={control}
                  onChange={([event]) => handlePeriodFinishedChange(event.target.value)}
                  defaultValue={periodFinished}
                  value={periodFinished}
                />
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid item md={4} xs={12} className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              <Search className={classes.icon} />
              {t('button:User_solictation.btn_advanced_filter')}
            </Button>
          </Grid>
          </form>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card
              title={`${t('cards:commons.card_enrollment')}`}
              number={indicators?.enrollmentsCount || 0}
              icon={AssignmentTurnedInOutlined}
              iconStyle={classes.blueIcon}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card
              title={`${t('cards:admin_dashboard.workload')}`}
              number={Math.floor(indicators?.workload)  || 0}
              icon={AccessTime}
              iconStyle={classes.greenIcon}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card
              title={`${t('cards:admin_dashboard.classes')}`}
              number={indicators?.finishedLessons  || 0}
              icon={Class}
              iconStyle={classes.greenIcon}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card
              title={`${t('title:admin_dashboard.finished_courses')}`}
              number={indicators?.finished  || 0}
              icon={SchoolOutlined}
              iconStyle={classes.yellowIcon}
            />
          </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: '10px'}}>
        <Grid item xs={12} md={6}>
          <ChartCard title={'Status dos Cursos'}>
            <PieChart data={mockData}/>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard title={'Top concluintes'}>
            <Podium data={topStudents} />
          </ChartCard>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{padding: '10px'}}>
        <Grid item xs={12} md={12}>
          <ChartCard title={t('title:admin_dashboard.courses_conclusion_counter')}>
            <TopUsersChart data={courses ?? []} backgroundColor='#176AE6' />
          </ChartCard>
        </Grid>
      </Grid>
    </div>
  );
};
