import NotificationData from "services/dataService/NotificationData";
import createNotification from "./NotificationFactory";
import createNotificationEmail from "./EmailFactory";

async function generateNotifications(type, data) {

    try {
        const notification = createNotification(type, data);
        const notificationEmail = createNotificationEmail(type, data);
        // debugger;

        const result = await NotificationData.sendNotification(notification);
        const result2 = await NotificationData.sendNotificationEmail(notificationEmail);
        
        // debugger;

        console.log("✅ Notificação gerada:", result);
        console.log("✅ Notificação e-mail gerada:", result2);

    } catch (error) {
        console.error("❌ Erro ao gerar notificação:", error);
    }
}

export default generateNotifications;
