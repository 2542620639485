import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const white = '#FFFFFF';

const TabsTheme = createTheme({
  palette: {
    primary: {
      main: white,
    },
  },
});

export default function AuthUserNavigation(props) {
  const { index } = props;
  const classes = useStyles();
  const { t } = useTranslation(['button']);
  const [enableCheck, setEnableCheck] = React.useState(false);

  const { only_invited_users, access_request } = useSelector((state) => state.company);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={TabsTheme}>
        <Tabs
          value={index}
          indicatorColor="primary"
          textColor="primary"
          bgcolor="primary"
          variant="fullWidth"
        >
          <RouterLink to="/login" style={{ color: '#ffff' }}>
            <Tab label={t('button:commons.btn_login')} />
          </RouterLink>
          {!only_invited_users && (
            <RouterLink to="/register" style={{ color: '#ffff' }}>
              <Tab label={t('button:commons.btn_signup')} />
            </RouterLink>
          )}
          {access_request && (
            <RouterLink to="/solicitation-access" style={{ color: '#ffff' }} >
              <Tab label={t('button:User_solictation.btn_solictation')} />
            </RouterLink>
          )}
        </Tabs>
      </ThemeProvider>
    </div>
  );
}
